<template>
  <div class="landing">
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <HeroSection/>
      <FeatureSection />
      <VideoSection/>
      <!--<ScreenshotSection />-->
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '@/components/ScrollupSection/scrollUp'
import HeaderSection from './Header'
import HeroSection from './Hero'
import FeatureSection from './Feature'
// import ScreenshotSection from './Screenshot'
import FooterSection from './Footer'
import VideoSection from './Video'

export default {
  name: 'LandingPage',
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    FeatureSection,
    //ScreenshotSection,
    FooterSection,
    VideoSection
  }
}
</script>

<style>

</style>
