<template>
  <div class="legal">
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-8">
              <div class="welcome-intro">
                <h1 class="text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">{{ $t('help.TITLE') }}</h1>
              </div>
            </div>
          </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape-bottom">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
          </svg>
        </div>
      </section>
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <img src="@/assets/img/help.jpg" alt="">
          </div>
        </div>
        <component v-bind:is="helpLocale"></component>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '@/components/ScrollupSection/scrollUp'
import HeaderSection from '@/components/common/HeaderLegal'
import HelpFr from './HelpFr'
import HelpEn from './HelpEn'
import FooterSection from '@/components/common/FooterLegal'

export default {
  name: 'HelpPage',
  components: {
    ScrollupSection,
    HeaderSection,
    FooterSection,
    'help-fr': HelpFr,
    'help-en': HelpEn
  },
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('cookies.TITLE')} - %s`
    }
  },
  computed: {
    helpLocale() {
      return `help-${this.$i18n.locale}`
    }
  }
}
</script>
