<template>
  <div class="legal">
    <ScrollupSection />
    <div class="main">
      <HeaderSection />
      <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-8">
              <div class="welcome-intro">
                <h1 class="text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">{{ $t('message.TITLE') }}</h1>
              </div>
            </div>
          </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape-bottom">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
          </svg>
        </div>
      </section>
      <div class="container">
        <div class="row d-flex justify-content-center">

          <vue-ellipse-progress
              v-show="isLoading"
              :loading="isLoading"
              :angle="-90"
              color="#50a5f1"
              emptyColor="#999"
              emptyColorFill="#f8f8fb"
              :size="100"
              :thickness="3"
              :emptyThickness="2"
              lineMode="out 2"
              animation="bounce 700 400"
              fontColor="#666"
              :half="false"
              class="mt-5 mb-5"
              fontSize="1rem">
          </vue-ellipse-progress>

          <div class="col-12 col-lg-6 mb-3">
            <md-card>
              <md-card-header v-if="!isLoading">
                <md-avatar>
                  <img src="@/assets/img/logo_spreedl_txt.png" alt="Avatar">
                </md-avatar>

                <div class="md-title">{{ message.operator }}</div>
                <div class="md-subhead">{{ message.createdAt }}</div>
              </md-card-header>
              <md-card-content v-if="!isLoading">
                <p v-if="message.originalId !== ''" class="text-muted mb-3">{{ $t("message.TYPE", { 'supportType': $t(`message.${message.type}`) }) }} <span class="text-primary">{{ message.originalId }}</span></p>
                <p class="text-body mb-4">{{ message.content }}</p>
                <div v-if="message.originalId !== '' && !message.closed">
                  <md-divider></md-divider>
                  <md-field class="mt-3 mb-1">
                    <label>{{ $t("message.REPLY_TXT") }}</label>
                    <md-textarea v-model="replyMessage" style="min-height: 150px"></md-textarea>
                  </md-field>

                  <md-field>
                    <md-icon>cloud_upload</md-icon>
                    <label>{{ $t("message.ATTACHMENT") }}</label>
                    <md-input :disabled="processingAttachment" :value="filename" @click="$refs.attachment.click()" :placeholder="$t('message.CLICK_TO_ATTACH')" />
                    <input type="file" ref="attachment" v-on:change="processAttachment" accept="image/*" class="d-none" />
                  </md-field>

                  <image-extended v-if="attachmentUrl" style="width:50px; height:50px;" :src="attachmentUrl" :alt="attachment" size="cover" />
                  <div class="d-flex justify-content-center">
                    <vue-recaptcha
                        class="mt-2"
                        ref="recaptcha"
                        :sitekey="captchaKey"
                        :loadRecaptchaScript="true"
                        @verify="captchaVerify"
                        @expired="captchaExpired"
                        @error="captchaError"
                    />
                    </div>
                </div>
                <div v-if="message.closed && message.originalId !== ''">
                  <md-divider></md-divider>
                  <p class="p-2 bg-light text-success mt-3 mb-1">
                    {{ $t("message.CLOSED_THREAD", { 'date': this.message.closedAt }) }}
                  </p>
                </div>
              </md-card-content>

              <md-card-actions class="bg-light" v-if="!isLoading && message.originalId !== '' && !message.closed">
                <md-button @click="sendReply" v-show="!isSending" class="md-raised md-primary">{{ $t("message.REPLY") }}</md-button>
                <vue-ellipse-progress
                    v-show="isSending"
                    :loading="isSending"
                    :angle="-90"
                    color="#50a5f1"
                    emptyColor="#999"
                    emptyColorFill="#f8f8fb"
                    :size="40"
                    :thickness="3"
                    :emptyThickness="2"
                    lineMode="out 2"
                    animation="bounce 700 400"
                    fontColor="#666"
                    :half="false"
                    class="mt-5 mb-5"
                    fontSize="1rem">
                </vue-ellipse-progress>
              </md-card-actions>
            </md-card>
          </div>

          <div class="col-12 col-lg-6 mb-3" v-if="message.originalId !== ''">
            <md-card>
              <md-card-header>
                <div class="md-title">{{ $t("message.HISTORY") }}</div>
              </md-card-header>
              <md-card-content>
                <vue-ellipse-progress
                    v-show="isLoadingHistory"
                    :loading="isLoadingHistory"
                    :angle="-90"
                    color="#50a5f1"
                    emptyColor="#999"
                    emptyColorFill="#f8f8fb"
                    :size="40"
                    :thickness="3"
                    :emptyThickness="2"
                    lineMode="out 2"
                    animation="bounce 700 400"
                    fontColor="#666"
                    :half="false"
                    class="mt-5 mb-5"
                    fontSize="1rem">
                </vue-ellipse-progress>

                <div v-if="!isLoadingHistory && history.length > 0">
                  <div :key="msg.messageId" v-for="msg in history">
                    <md-divider></md-divider>
                    <md-card-header>
                      <md-avatar v-if="msg.isSupport === 1" >
                        <img src="@/assets/img/logo_spreedl_txt.png" alt="Avatar">
                      </md-avatar>
                      <md-avatar class="md-avatar-icon md-accent" v-else>{{ msg.senderName.substr(0, 1) }}</md-avatar>
                      <div class="md-title">{{ msg.senderName }}</div>
                      <div class="md-subhead"><small>{{ msg.createdAt }}</small></div>
                    </md-card-header>
                    <md-card-content>
                      <p class="text-body">{{ msg.message }}</p>
                    </md-card-content>
                  </div>
                </div>

                <p v-if="history.length === 0 && !isLoadingHistory" class="text-muted">{{ $t("message.HISTORY_EMPTY") }}</p>

              </md-card-content>
            </md-card>

          </div>

        </div>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '@/components/ScrollupSection/scrollUp'
import HeaderSection from '@/components/common/HeaderLegal'
import FooterSection from '@/components/common/FooterLegal'
import ImageExtended from '@/components/common/ImageExtended'
import http from "@/utils/http-common";
import moment from "moment";
import { VueRecaptcha } from 'vue-recaptcha';
import config from '@/config/config';

export default {
  name: 'MessagePage',
  components: {
    ScrollupSection,
    HeaderSection,
    FooterSection,
    ImageExtended,
    VueRecaptcha,
  },
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('message.TITLE')} - %s`
    }
  },
  props: {
    messageId: {
      type: String,
      default: () => ""
    },
    mId: {
      type: String,
      default: () => ""
    }
  },

  data() {
    return {
      processingAttachment: false,
      isLoading: false,
      isLoadingHistory: false,
      isSending: false,
      history: [],
      replyMessage: '',
      attachment: null,
      attachmentUrl: null,
      message: {
        createdAt: '',
        content: '',
        operator: '',
        type: '',
        originalId: '',
        closed: false,
        closedAt: null
      },
      captchaResponse: null
    }
  },

  methods: {

    async processAttachment() {
      try {
        const file = this.$refs.attachment.files[0];
        if (file && file.name) {
          this.processingAttachment = true;

          if (file.size > 1024 * 1024 * 30) {
            this.$toast.error(this.$t("message.ERROR_FILE_TOO_BIG", {'size': 30}));
            this.attachment = null;
            this.attachmentUrl = null;
            this.processingAttachment = false;
            this.$refs.attachment.value = null;
            return;
          }

          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener("load", () => {
            // this is to load image on the UI
            // .. not related to file upload :)
            this.attachmentUrl = fr.result;
          });
          this.attachment = file;
        }
      } catch (error) {
        this.attachmentUrl = null;
        this.attachment = null;
        this.$refs.attachment.value = null;
      } finally {
        this.processingAttachment = false;
      }
    },

    sendReply() {
      if (this.replyMessage === "") {
        this.$toast.error(this.$t("message.ERROR_EMPTY_MSG"));
        return;
      }

      if (this.captchaResponse === null) {
        this.$toast.error(this.$t("message.ERROR_CAPTCHA"));
        return;
      }

      this.isSending = true;
      let task;
      if (this.attachment) {
        const formData = new FormData();
        formData.append("msgid", this.messageId);
        formData.append("mid", this.mId);
        formData.append("oid", this.message.originalId);
        formData.append("type", this.message.type);
        formData.append("reply", this.replyMessage);
        formData.append("file", this.attachment);
        formData.append("captcha", this.captchaResponse);
        task = http.post("/messages/reply", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        task = http.post("/messages/reply", {
          reply: this.replyMessage,
          msgid: this.messageId,
          mid: this.mId,
          oid: this.message.originalId,
          type: this.message.type,
          captcha: this.captchaResponse
        });
      }

      task
        .then(response => {
          if (response.data.status === true) {
            this.isSending = false;
            this.$toast.success(this.$t("message.SUCCESS_SENDING"));
            this.replyMessage = "";
            this.attachment = null;
            this.attachmentUrl = null;
            this.$refs.attachment.value = null;
            this.loadMessage();
          }
          else {
            this.isSending = false;
            switch (response.data.message) {
              case 'closed':
                this.$toast.error(this.$t("message.ERROR_CLOSED"));
                this.message.closed = true;
                break;
              case 'empty_reply':
                this.$toast.error(this.$t("message.ERROR_EMPTY_MSG"));
                break;
              default:
                this.$toast.error(this.$t("message.ERROR_SENDING_REPLY"));
            }
          }
        })
        .catch(error => {
          this.isSending = false;
          window.console.error(error);
          this.$toast.error(this.$t("message.ERROR_SENDING_REPLY"));
        });
    },

    loadHistory() {
      this.isLoadingHistory = true;
      try {
        http.post("/messages/history", {
          oid: this.message.originalId,
          type: this.message.type,
          msgid: this.messageId,
          mid: this.mId
        })
        .then(response => {
          if (response.data.result) {
            if (this.history.length > 0) {
              this.history = [];
            }

            for (const msg of response.data.result) {
              msg.createdAt = moment(msg.createdAt).format('lll');
              if (msg.senderName === 'me') {
                msg.senderName = this.$t("message.ME");
              }
              this.history.push(msg);
            }
          }
          this.isLoadingHistory = false;
        })
        .catch(error => {
          window.console.error(error);
          this.isLoadingHistory = false;
        });
      }
      catch (error) {
        window.console.error(error);
        this.isLoadingHistory = false;
      }
    },

    loadMessage() {
      this.isLoading = true;
      try {
        http.post("/messages/get", {
          msgid: this.messageId,
          mid: this.mId
        })
        .then(response => {
          this.message = response.data.result;
          this.message.createdAt = moment(this.message.createdAt).format('lll')
          this.message.closedAt = moment(this.message.closedAt).format('lll')
          this.isLoading = false;

          this.loadHistory();
        })
        .catch(error => {
          window.console.error(error);
          this.isLoading = false;
        });
      }
      catch (error) {
        window.console.error(error);
        this.isLoading = false;
      }
    },

    captchaVerify(response) {
      if (response) {
        this.captchaResponse = response;
      } else {
        this.captchaResponse = null;
      }
    },

    captchaError() {
      this.captchaResponse = null;
    },

    captchaExpired() {
      this.captchaResponse = null;
      this.$refs.recaptcha.reset();
    }
  },

  computed: {
    filename: function () {
      return this.attachment ? this.attachment.name : '';
    },

    captchaKey: function() {
      return config.captchaKey;
    }
  },

  created() {
    if (this.messageId === "") {
      this.$router.push('/404');
    }
    else {
      this.loadMessage();
    }
  }
}
</script>

<style scoped>

</style>