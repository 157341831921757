<template>
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
            <div class="container">
                <div class="row align-items-center">
                    <!-- Welcome Intro Start -->
                    <div class="col-12 col-md-7 col-lg-6">
                        <div class="welcome-intro text-center text-md-left ">
                            <img src="@/assets/img/logo_txt_white.png" class="mb-3 mb-md-5" style="height:180px" />
                            <h1 class="text-white">{{ $t("landing.HERO_TITLE") }}</h1>
                            <p class="text-white my-4">{{ $t("landing.HERO_SUBTITLE") }}</p>
                            <!-- Store Buttons -->
                            <div class="button-group d-flex store-buttons d-md-block justify-content-center">
                              <a href="https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dspreedl.com%26utm_campaign%3Dfree%26utm_medium%3Dsite%26utm_content%3Dps_btn1">
                                <img src="@/assets/img/google_play.png" alt="">
                              </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 col-lg-6">
                        <!-- Welcome Thumb -->
                        <div class="welcome-thumb mx-auto text-center" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                          <div style="width:320px;height:580px;" class="position-relative d-inline-block">
                            <div class="position-absolute mx-auto" style="width:265px;height:467px;top:36px;left:0;right:0;">
                              <video
                                    loop
                                    crossorigin
                                    muted
                                    autoplay
                                    playsinline
                                    poster="@/assets/img/video-poster.jpg"
                                    class="w-100"
                                >
                                  <source
                                      src="@/assets/video/video.webm"
                                      type="video/webm; codecs=vp8, vorbis"
                                  />
                                  <source
                                      src="@/assets/video/video.mp4"
                                      type="video/mp4"
                                  />
                                </video>
                              </div>
                              <img src="@/assets/img/video-bg.png" class="position-absolute mx-auto" style="width:320px;left:0;right:0;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Shape Bottom -->
            <div class="shape-bottom">
                <svg viewBox="0 0 1920 310" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg replaced-svg">
                    <title>sApp Shape</title>
                    <desc>Created with Sketch</desc>
                    <defs></defs>
                    <g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                            <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395" id="sApp-v1.0"></path>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style>

</style>
