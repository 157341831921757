<template>
  <li class="nav-item dropdown" v-if="langs.length > 1">
    <a class="nav-link dropdown-toggle" href="javascript:;" id="localesLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ displayLocale }}
    </a>
    <ul class="dropdown-menu" aria-labelledby="localesLink">
      <li v-for="(item, i) in langs"
          :key="`Lang${i}`"
          @click="switchLocale(item.lang)"
          >
        <a
            class="dropdown-item"
            href="javascript:;"
            @click="switchLocale(item.lang)">
            <country-flag :country="item.flag" size="small" class="align-middle"/>
            <span class="align-middle ml-2">{{ item.name }}</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  data() {
    return {
      langs: [
        /*{
          lang: 'en',
          name: 'English',
          flag: 'gb'
        },*/
        {
          lang: 'fr',
          name: 'Français',
          flag: 'fr'
        }
      ]
    }
  },
  components: {
    CountryFlag
  },
  methods: {
    switchLocale(lang) {
      this.$store.dispatch('setLocale', lang)
    }
  },
  computed: {
    displayLocale() {
      return this.$i18n.locale.toUpperCase()
    }
  }
}
</script>
