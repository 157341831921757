<template>
  <div>
    <p class="p-5 text-center">{{ $t("payments.SECURE3D_WAITING")}}</p>
    <div class="mt-3 ms-3 me-3 justify-content-center mt-2 text-center">
      <md-progress-spinner v-show="processing"  md-mode="indeterminate" class="mt-4"></md-progress-spinner>
      <div v-show="status === 'S' " class="bg-success rounded p-3 text-white">
        {{ $t("payments.SUCCESS_PAYMENT")}}
      </div>
      <div v-show="status === 'P' " class="bg-light rounded p-3 text-dark">
        {{ $t("payments.PROCESSING_PAYMENT")}}
      </div>
      <div v-show="status === 'E' " class="bg-warning rounded p-3 text-white">
        {{ $t("payments.ERROR_PAYMENT_ERROR")}}
      </div>
      <div v-show="status === 'F' " class="bg-danger rounded p-3 text-white">
        {{ $t("payments.ERROR_PAYMENT_FAIL")}}
      </div>
    </div>
  </div>
</template>

<script>

import isEmpty from "is-empty";
import paymentConfig from "@/config/payment.config";
import { loadStripe } from '@stripe/stripe-js'

let stripe;

export default {
  name: "StripeResult",

  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('payments.TITLE')} - %s`,
    }
  },

  data() {
    return {
      status: '',
      processing: true
    }
  },

  methods: {

    logD(message) {
      if (paymentConfig.config.sp.debug)
        window.console.log(message);
    },
  },

  async mounted() {
    stripe = await loadStripe(paymentConfig.config.sp.apiKey);

    let clientSecret = "";
    const qs = this.$route.query
    this.logD(qs);
    if (!isEmpty(qs) && !isEmpty(qs.payment_intent_client_secret)) {
      clientSecret = qs.payment_intent_client_secret;
    }
    this.logD(`clientSecret: ${clientSecret}`);
    const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);
    this.logD("result");
    this.logD(paymentIntent);

    if (paymentIntent.status === 'succeeded') {
      this.status = 'S';
      setTimeout(() => {
        this.$router.replace("/payments/success");
      }, 2000);
    }
    else if (paymentIntent.status === 'processing') {
      this.status = 'P';
    }
    else if (paymentIntent.status === 'require_payment_method') {
      this.status = 'F';
    }
    else {
      this.status = 'E';
    }
    this.processing = false;
  },
}
</script>

<style scoped>

</style>
