<template>
  <section id="video" class="section style-two overflow-hidden pb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-7">
          <!-- Section Heading -->
          <div class="section-heading text-center mb-4">
            <h2>{{ $t("landing.VIDEO_TITLE") }}</h2>
            <p class="d-block mt-4">{{ $t("landing.VIDEO_SUBTITLE") }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <video
              crossorigin
              controls
              playsinline
              poster="@/assets/img/ad-poster.jpg"
              class="w-100 shadow rounded"
          >
            <source
                src="@/assets/video/ad.webm"
                type="video/webm; codecs=vp8, vorbis"
            />
            <source
                src="@/assets/video/ad.mp4"
                type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VideoSection'
}
</script>

<style scoped>

</style>