export function setDocumentLang(lang) {
    document.documentElement.lang = lang
}

export function setDocumentTitle(newTitle) {
    document.title = newTitle
}

export function setDocumentMetaDescription(description) {
    document.querySelector("meta[name='description']" ).setAttribute("content", description);
}

export function setDocumentMetaKeywords(keywords) {
    document.querySelector("meta[name='keywords']" ).setAttribute("content", keywords);
}
