<template>
    <footer class="section inner-footer bg-gray ptb_50">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-6">
                        <!-- Footer Items -->
                        <div class="footer-items text-center">
                            <!-- Logo -->
                            <a class="navbar-brand" href="#">
                                <img class="logo" src="@/assets/img/logo.png" alt="">
                            </a>
                            <!-- Copyright Area -->
                            <div class="copyright-area border-0 pt-3">
                                <p>&copy; {{ new Date().getFullYear() }} <strong>Spreedl</strong> <i class="fas fa-heart"></i> {{ $t("landing.FOOTER_BY") }} Pickup Agency AG</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>
export default {
    
}
</script>

<style>

</style>