<template>
  <div class="container">
    <div class="row pt-3 pl-3 pr-3">
      <div class="col-12" v-show="processing && processingSecure">
        <iframe style="border:0;top:0;left:0;right:0;bottom:0;position:fixed" allow="fullscreen" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin allow-scripts allow-popups allow-forms" ref="secureFrame" width="100%" height="100%"></iframe>
      </div>
      <div class="col-12" v-show="!processingSecure">
        <form id="cardForm">

          <div class="row mb-2" v-if="ccData.length > 0">
            <div class="col-12">
              <label>{{ $t('payments.USE_OLD_CARD') }}</label>
              <div class="ccContainer">
                <div v-for="cc in ccData" :key="cc.cardId" class="input-group mb-2" @click="askCVC(cc)">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <img :src="cardUsedBrandClass(cc.brand)" class="ccBrand" :alt="cc.brand" :title="cc.brand" />
                    </span>
                  </div>
                  <div class="form-control ccDataContent">
                    <span class="ccPan">{{ cc.pan.replaceAll("X", "*") }}</span>
                    <span class="ccExpire text-primary">{{ cc.expm }} / {{ cc.expy }}</span>
                    <span class="ccHolder">{{ cc.holder }}</span>
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text" style="background: #fff !important;">
                      <md-button class="md-icon-button md-dense md-accent" :disabled="processing" @click="askCVC(cc)">
                        <md-icon class="fas fa-check"></md-icon>
                      </md-button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3">
                <i class="fa fa-plus"></i> <span class="text-primary text-underlined" style="font-size:15px;" @click="showAddCard = !showAddCard"><b>{{ $t('payments.USE_NEW_CARD') }}</b></span>
              </div>

            </div>
          </div>

          <div v-show="showAddCard">

            <div class="row">
              <div class='col-12'>
                <div class="form-group">
                  <label>{{ $t('payments.CARD_NUMBER') }}</label>
                  <div class="input-group mb-0">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i :class="cardBrandClass"></i></span>
                    </div>
                    <input id="cardNumInput" ref="cardNumInput" :data-error="!!(cardErrors.cardNumber)" v-model="cardNumber" type="tel" class="form-control" placeholder="#### #### #### ####" v-cardformat:formatCardNumber>
                  </div>
                  <div v-if="cardErrors.cardNumber" class="error">
                    <small>{{ cardErrors.cardNumber }}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class='col-12'>
                <div class="form-group">
                  <label>{{ $t('payments.CARD_HOLDERNAME') }}</label>
                  <input id="cardHolderInput" ref="cardHolderInput" :data-error="!!(cardErrors.cardHolder)" v-model="cardHolder" class="form-control">
                  <div v-if="cardErrors.cardHolder" class="error">
                    <small>{{ cardErrors.cardHolder }}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class='col-6'>
                <div class="form-group">
                  <label>{{ $t('payments.CARD_EXPIRY') }}</label>
                  <input ref="cardExpInput" id="card-exp" :data-error="!!(cardErrors.cardExpiry)" v-model="cardExpiry" maxlength="10" type="tel" class="form-control" v-cardformat:formatCardExpiry>
                  <div v-if="cardErrors.cardExpiry" class="error">
                    <small>{{ cardErrors.cardExpiry }}</small>
                  </div>
                </div>
              </div>
              <div class='col-6'>
                <div class="form-group">
                  <label>{{ $t('payments.CARD_CVV') }}</label>
                  <input ref="cardCvcInput" :data-error="!!(cardErrors.cardCvc)" v-model="cardCvc" type="tel" class="form-control" v-cardformat:formatCardCVC>
                  <div v-if="cardErrors.cardCvc" class="error">
                    <small>{{ cardErrors.cardCvc }}</small>
                  </div>
                </div>
              </div>
            </div>

            <input id='selected-brand' v-model="cardBrand" type='hidden'>

          </div>
        </form>
        <div class="justify-content-center mt-2 text-center">
          <md-button class="md-raised md-accent" id="validCardBtn" :disabled="processing" @click="process">{{ $t('payments.VALID')}}</md-button>
          <md-progress-spinner  md-mode="indeterminate" v-show="processing" class="mt-2"></md-progress-spinner>
        </div>
        <div class="justify-content-center mt-3 text-center">
          <img src="@/assets/img/secured-payment.jpg" alt="" class="d-inline-block mb-2" />
          <p class="text-body" style="font-size: 11px; line-height: normal !important;">{{ $t("payments.SECURE3D_TEXT") }}</p>
        </div>
      </div>
    </div>

    <transition name="modal">
      <div class="modal-mask" v-show="showModalCVC">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <h6>{{ $t("payments.ADD_CVC") }}</h6>
            </div>

            <div class="modal-body">
              <img src="@/assets/img/cvv.png" />
              <form id="cvcForm" @submit.prevent="">

                <div class="row mt-2">
                  <div class='col-12 text-center'>
                    <span class="text-body"><b>{{ currentCard.pan.replaceAll("X", "*") }}</b></span>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class='col-12'>
                    <label class="d-inline-block mr-2 mt-2">{{ $t('payments.CARD_ASK_CVV') }}</label>
                    <input v-model="askCardCvc" v-on:keydown.enter="processCVC()" type="tel" class="form-control d-inline-block float-right" style="width: 60px;" />
                  </div>
                </div>

              </form>
            </div>

            <div class="modal-footer">
              <md-button @click="hideCVC()">{{ $t("common.CANCEL") }}</md-button>
              <md-button class="md-raised md-accent" @click="processCVC()">{{ $t("common.VALID") }}</md-button>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import isEmpty from "is-empty";
import http from "@/utils/http-common";
import paymentConfig from "@/config/payment.config";
import cryptoUtils from "@/utils/crypto";

export default {
  name: "CardPP",

  props: {
    params: {
      type: String,
      default: () => ""
    },

    ccData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      processingSecure: false,
      processing: false,
      cardBrand: null,
      cardNumber: null,
      cardExpiry: null,
      cardHolder: null,
      cardCvc: null,
      showModalCVC: false,
      askCardCvc: null,
      currentCard: {pan: ''},
      rsaPublicKey: "",
      cardErrors: {},
      showAddCard: true,
      trackCardNumberChar: false,
      trackCardNumberCharBrand: false,
    }
  },

  computed: {
    cardBrandClass(){
      return this.getBrandClass(this.cardBrand);
    },

  },

  watch: {

    cardNumber: function(val) {
      //this.$cardFormat.formatCardNumber(document.getElementById("cardNumInput"));
      if (val.length >= 8) {
        this.detectBrand(val);
        if (!this.trackCardNumberCharBrand) {
          this.trackCardNumberCharBrand = true;
          this.$logEvent("card_number_detectBrand");
        }
      }
      if (!this.trackCardNumberChar) {
        this.trackCardNumberChar = true;
        this.$logEvent("card_number_keypress");
      }
      this.cardErrors.cardNumber = null;
    },

    cardExpiry: function(val) {
      this.cardErrors.cardExpiry = null;
      let _expiry = val.replaceAll(" ", "").split("/");
      if (_expiry.length === 2 && _expiry[1].length > 2) {
        let expy = _expiry[1].substring(2);
        val = _expiry[0] + "/" + expy
        this.cardExpiry = val;
        return;
      }

      if (this.$cardFormat.validateCardExpiry(val)) {
        this.logD("cardExpiry watch valid");
        if (isEmpty(this.cardCvc)) {
          this.$refs.cardCvcInput.focus();
        }
      }
    },

    cardCvc: function(val) {
      this.cardErrors.cardCvc = null;
      if (this.$cardFormat.validateCardCVC(val)) {
        this.logD("cardCvc watch valid");
        if (isEmpty(this.cardHolder)) {
          this.$refs.cardHolderInput.focus();
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    cardHolder: function(val) {
      this.cardErrors.cardHolder = null;
    }

  },

  methods: {

    logD(message) {
      if (paymentConfig.config.pp.debug) {
        window.console.log(message);
      }
    },

    logE(error) {
      if (paymentConfig.config.pp.debug) {
        window.console.error(error);
      }
    },

    /*
      Forms methods
     */

    cardUsedBrandClass(brand) {
      let icon = '';
      brand = brand || 'unknown';
      let cardBrandToClass = {
        'visa': 'cc-visa.svg',
        'mastercard': 'cc-mastercard.svg',
        'amex': 'cc-amex.svg',
        'discover': 'cc-discover.svg',
        'diners': 'cc-diners-club.svg',
        'jcb': 'cc-jcb.svg',
        'unknown': 'credit-card.svg',
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }
      return require('@/assets/img/'+icon);
    },

    setIframeHTML(html) {
      this.processingSecure = true;
      const iframe = this.$refs.secureFrame;
      if (typeof iframe.srcdoc !== 'undefined') {
        iframe.srcdoc = html;
      } else {
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();
      }
    },

    detectBrand(number) {
      this.logD("detectBrand");
      if (typeof window.dalenys !== "undefined") {
        this.logD("detectBrand dalenys defined");
        const bin = number.replaceAll(' ', '').substring(0, 8);
        let that = this;
        window.dalenys.brandDetector.detectBrandsByBin(bin, function (brands) {
          that.logD("brands are: " + JSON.stringify(brands));
          that.manageDefaultBrand(brands)
        });
      }
    },

    manageDefaultBrand(brands) {
      if(!isEmpty(brands)) {
        this.cardBrand = brands[0]['brand'];
      }
    },

    getBrandClass (brand) {
      let icon = '';
      brand = brand || 'unknown';
      let cardBrandToClass = {
        'visa': 'fab fa-cc-visa',
        'mastercard': 'fab fa-cc-mastercard',
        'amex': 'fab fa-cc-amex',
        'discover': 'fab fa-cc-discover',
        'diners': 'fab fa-cc-diners-club',
        'jcb': 'fab fa-cc-jcb',
        'unknown': 'fa fa-credit-card',
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }

      return icon;
    },

    validateCardHolder(str) {
      if (isEmpty(str))
        return false;

      str = str.trim();
      str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const regexPattern = /[^A-Za-z1-9 -]/g;
      str = str.replace(regexPattern, "");
      if (str.length < 2) {
        return false;
      }
      this.cardHolder = str;
      return true;
    },

    /*
      API methods
     */

    async getEncryptKey() {
      this.logD("getEncryptKey");
      let rsaPublicKey = null;

      try {
        const rsaKeyServiceUrl = paymentConfig.config.pp.rsaKeyUrl;
        const response = await http.post(rsaKeyServiceUrl, {
          apiKeyId: paymentConfig.config.pp.apiKeyId,
        });
        this.logD("getEncryptKey response: " + JSON.stringify(response));
        rsaPublicKey = response.data;
      } catch (error) {
        this.logE("getEncryptKey " + error.message);
      }

      return rsaPublicKey;
    },

    async encryptPayload(cardData, rsaKey) {
      this.logD("encryptPayload");
      let encryptedMsg = await this.encryptPlainText(
          JSON.stringify(cardData),
          rsaKey.encryptionPublicKey
      );
      this.logD("encryptedMsg: " + JSON.stringify(encryptedMsg));
      return {
        ENCRYPTEDDATA: encryptedMsg,
        ENCRYPTIONKEYID: rsaKey.encryptionKeyId,
        APIKEYID: paymentConfig.config.pp.apiKeyId,
      };
    },

    async tokenize(encryptedData) {
      this.logD("tokenize encryptedData: " + JSON.stringify(encryptedData));
      try {
        const response = await http.post(paymentConfig.config.pp.tokenizeUrl, JSON.stringify(encryptedData));
        this.logD("tokenize response " + JSON.stringify(response));
        return response.data;
      } catch (error) {
        this.logE("tokenize " + error.message);
        return null;
      }
    },

    /*
      Process methods
     */

    askCVC(cc) {
      this.currentCard = cc;
      this.showModalCVC = true;
    },

    hideCVC() {
      this.currentCard = {pan: ''};
      this.askCardCvc = '';
      this.showModalCVC = false;
    },

    async processCVC() {
      if (!this.$cardFormat.validateCardCVC(this.askCardCvc)) {
        this.$toast.error(this.$t("payments.ERROR_CVV").toString());
        return;
      }

      this.showModalCVC = false;
      await this.processCard();
    },

    async processCard() {
      if (this.processing) {
        return;
      }

      this.processing = true;

      const jsonData = {
        TOKEN: this.currentCard.token,
        CARDFULLNAME: this.currentCard.holder,
        CARDVALIDITYDATE: this.currentCard.expm + "-" + this.currentCard.expy,
        CARDCVV: this.askCardCvc.replaceAll(' ', ''),
        SELECTEDBRAND: this.currentCard.brand,
        CARDID: this.currentCard.cardId
      };

      this.sendPayment(jsonData);
    },

    async process() {
      if (this.processing) {
        return;
      }

      this.processing = true;

      // init
      this.cardErrors = {};

      // validate card number
      if(!this.$cardFormat.validateCardNumber(this.cardNumber) && this.cardNumber !== "5555 5555 5555 5550" && this.cardNumber !== "5555 5555 5555 5559"){
        this.cardErrors.cardNumber = this.$t("payments.ERROR_NUMBER");
      }

      // validate card expiry
      if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
        this.cardErrors.cardExpiry = this.$t("payments.ERROR_EXPIRY");
      }

      // validate card CVC
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = this.$t("payments.ERROR_CVV");
      }

      // validate card Holder Name
      if (!this.validateCardHolder(this.cardHolder)) {
        this.cardErrors.cardHolder = this.$t("payments.ERROR_HOLDER");
      }

      if (!isEmpty(this.cardErrors)) {
        this.processing = false;
        return;
      }

      if (isEmpty(this.cardBrand)) {
        this.processing = false;
        this.$toast.error(this.$t("payments.ERROR_EMPTY_BRAND").toString());
        return;
      }

      const jsonData = {
        CARDCODE: this.cardNumber.replaceAll(' ', ''),
        CARDFULLNAME: this.cardHolder,
        CARDVALIDITYDATE: this.cardExpiry.replace('/', '-').replaceAll(' ', ''),
        CARDCVV: this.cardCvc.replaceAll(' ', ''),
        SELECTEDBRAND: this.cardBrand
      };

      /*
      const rsaKey = await this.getEncryptKey();
      if (isEmpty(rsaKey)) {
        this.processing = false;
        return;
      }

      const encryptedPayload = await this.encryptPayload(jsonData, rsaKey);
      if (isEmpty(encryptedPayload)) {
        this.processing = false;
        return;
      }

      const tokenizeResult = await this.tokenize(encryptedPayload)
      if (isEmpty(tokenizeResult.EXECCODE) || tokenizeResult.EXECCODE !== "0000") {
        this.processing = false;
        return;
      }
       */
      this.sendPayment(jsonData);
    },

    sendPayment(tokenData) {
      http.post("/payments/process-card", {
        data: this.params,
        sysId: "PPG",
        tokenData: cryptoUtils.base64Encode(JSON.stringify(tokenData)),
      })
          .then(response => {
            if (isEmpty(response.data.status) || response.data.status === false) {
              this.$toast.error(this.$t("payments.ERROR_PAYMENT_ERROR"));
              this.processing = false;
              return;
            }

            if (response.data.result.secure === true && !isEmpty(response.data.result.secureContent)) {
              // 3Dsecure
              this.$toast.info(this.$t("payments.PAYMENT_3DSECURE_REDIRECT"));
              setTimeout(() => {
                this.setIframeHTML(cryptoUtils.base64Decode(response.data.result.secureContent));
              }, 1000);
            }
            else if (response.data.result.success === true) {
              this.$toast.success(this.$t("payments.SUCCESS_PAYMENT"));
              this.$router.replace("/payments/success");
            }
            else {
              this.processing = false;
            }
          })
          .catch((err) => {
            this.logE("sendPayment ERROR " + err.message);
            this.processing = false;
            this.$toast.error(this.$t("error.GLOBAL").toString());
          })
    },

    encryptPlainText(plainText, publicKey) {
      let cryptoSubtle;
      let btoa = window.btoa;
      let atob = window.atob;
      // Fix Apple prefix if needed
      if (
          window.crypto &&
          !window.crypto.subtle &&
          window.crypto.webkitSubtle
      ) {
        cryptoSubtle = window.crypto.subtle;
      } else if (window.crypto && window.crypto.subtle) {
        cryptoSubtle = window.crypto.subtle;
      }

      if (!cryptoSubtle) {
        throw "No crypto api";
      }

      function getMessageEncoding(message) {
        let enc = new TextEncoder();
        return enc.encode(message);
      }

      function encryptMessage(publicKey, message) {
        let encoded = getMessageEncoding(message);
        return cryptoSubtle.encrypt(
            {
              name: "RSA-OAEP",
            },
            publicKey,
            encoded
        );
      }

      function arrayBufferToBase64(buffer) {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      }

      function strToArrayBuffer(str) {
        const buf = new ArrayBuffer(str.length);
        let bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
          bufView[i] = str.charCodeAt(i);
        }
        return buf;
      }

      function importRsaKey(pem) {
        // base64 decode the string to get the binary data
        const binaryDerString = atob(pem);
        // convert from a binary string to an ArrayBuffer
        const binaryDer = strToArrayBuffer(binaryDerString);
        return cryptoSubtle.importKey(
            "spki",
            binaryDer,
            {
              name: "RSA-OAEP",
              hash: "SHA-1",
            },
            true,
            ["encrypt"]
        );
      }

      return importRsaKey(publicKey)
          .then((binaryPublicKey) => {
            return encryptMessage(binaryPublicKey, plainText);
          })
          .then((encryptedBytes) => {
            return arrayBufferToBase64(encryptedBytes);
          });
    },
  },

  mounted() {
    let brandScript = document.createElement('script')
    brandScript.setAttribute('type', 'text/javascript');
    if (paymentConfig.config.pp.debug)
      brandScript.setAttribute('src', 'https://js.sandbox.dalenys.com/brand-detector/v1/brand-detector.min.js');
    else
      brandScript.setAttribute('src', 'https://js1.dalenys.com/brand-detector/v1/brand-detector.min.js');
    document.head.appendChild(brandScript)

    if (this.ccData.length === 0) {
      this.showAddCard = true;
    }
    else {
      this.showAddCard = false;
    }
  }
}
</script>

<style scoped>
#validCardBtn {
  width: 100% !important;
  margin: auto !important;
}

#cardForm label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #423838;
  width: 100%;
  margin-bottom: 10px !important;
}

#cardForm input[data-error="true"]{
  border-color: #dc3545;
  border-width: 2px;
}

#cardForm div.error {
  color: #dc3545;
}

.ccContainer {
  max-height: 170px;
  overflow-y: auto;
  scroll-behavior: auto;
}

.ccBrand {
  width:25px;
}

.ccDataContent {
  background: #fff;
  height: auto !important;
  align-items: center;
  border-right:0 !important;
}

.ccPan {
  font-size: 13px;
  color: black;
  display: block;
}

.ccExpire {
  display: block;
  font-size: 12px;

}

.ccHolder {
  color: #666;
  font-size: 12px;
  display: block;
  margin-top: 2px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {

}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


</style>