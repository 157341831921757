import axios from "axios";

const axiosConfig = {
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: {
        "Content-type": "application/json",
    }
};
const axiosClient = axios.create(axiosConfig);

export default axiosClient;