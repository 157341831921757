import Vue from 'vue'
import Router from 'vue-router'

import Landing from "@/components/Landing/Landing"
import Contact from "@/components/Contact/Contact"
import Terms from "@/components/LegalTermsPage/Terms"
import Unsubscribe from "@/components/NewsletterPage/Unsubscribe"
import ValidateEmail from "@/components/AccountPage/ValidateEmail.vue"
import Cookies from "@/components/LegalCookiesPage/Cookies"
import Privacy from "@/components/LegalPrivacyPage/Privacy"
import Error from "@/components/InnerPages/Error/404"
import Help from "@/components/HelpPage/Help"
import Message from "@/components/Message/Message"
import PaymentCard from "@/components/Payment/Card"
import PaymentSecure from "@/components/Payment/Secure"
import PaymentSecureResult from "@/components/Payment/SecureResult"
import PaymentSuccessResult from "@/components/Payment/SuccessResult"
import PaymentStripeResult from "@/components/Payment/StripeResult"

import http from "@/utils/http-common";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      alias: '/home',
      name: 'Landing',
      component: Landing
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/cookies',
      name: 'Cookies',
      component: Cookies
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/help',
      name: 'Help',
      component: Help
    },
    {
      path: '/newsletter/unsubscribe/:newsletterType/:data',
      name: 'Unsubscribe',
      component: Unsubscribe,
      props: route => ({
        newsletterType: route.params.newsletterType,
        params: route.params.data
      })
    },
    {
      path: '/account/email/:action/:data',
      name: 'ValidateEmail',
      component: ValidateEmail,
      props: route => ({
        params: route.params.data,
        action: route.params.action
      })
    },
    {
      path: '/payments/card/:data',
      name: 'PaymentCard',
      component: PaymentCard,
      props: route => ({
        params: route.params.data,
      })
    },
    {
      path: '/payments/secure/:data',
      name: 'PaymentSecure',
      component: PaymentSecure,
      props: route => ({
        params: route.params.data,
      })
    },
    {
      path: '/payments/success',
      name: 'PaymentSuccess',
      component: PaymentSuccessResult,
    },
    {
      path: '/payments/redirect',
      name: 'PaymentSecureResult',
      component: PaymentSecureResult,
    },
    {
      path: '/payments/stripe/result',
      name: 'PaymentStripeResult',
      component: PaymentStripeResult,
    },
    {
      path: '/messages/read/:mid/:gid',
      name: 'Message',
      component: Message,
      props: route => ({
        messageId: route.params.gid,
        mId: route.params.mid
      })
    },
    {
      path: '/g/:url',
      name: 'Store',
      beforeEnter(to) {
        const url = to.params.url;
        let storeUrl = "";
        switch (url) {
          // Meta
          case 'MDM1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dmeta%26utm_medium%3Ddisplay%26utm_campaign%3DMDM1";
            break;
          case 'MDW1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dmeta%26utm_medium%3Ddisplay%26utm_campaign%3DMDW1";
            break;

          // SMS
          case 'SM1S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSM1S";
            break;

          case 'SW1S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSW1S";
            break;

          case 'SM2S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSM2S";
            break;

          case 'SW2S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSW2S";
            break;

          case 'SM3S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSM3S";
            break;

          case 'SW3S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSW3S";
            break;

          case 'SM4S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSM4S";
            break;

          case 'SW4S':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dhug%26utm_medium%3Dsms%26utm_campaign%3DSW4S";
            break;

          // Influencers
          case 'IDV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIDV1";
            break;

          case 'IMV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIMV1";
            break;

          case 'IJV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIJV1";
            break;

          case 'ITV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DITV1";
            break;

          case 'IF1V':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIF1V";
            break;

          case 'IIV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIIV1";
            break;

          case 'IBV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIBV1";
            break;

          case 'IAV1':
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dinfluencer%26utm_medium%3Dtiktok%26utm_campaign%3DIAV1";
            break;

          default:
            storeUrl = "https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dspreedl.com%26utm_medium%3Dstore%26utm_campaign%3Dstore";
        }
        http.get(`/store/track/${url}`)
        .catch((error) => {
          window.console.error(error);
        });
        setTimeout(() => {
          window.location.href = storeUrl;
        }, 500);
        return false;
      },
    },
    {
      path: '/app/join/:data',
      name: 'Share',
      beforeEnter(to) {
        const data = to.params.data || 'null';
        const storeUrl = `https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dspreedl.com%26utm_medium%3D${data}%26utm_campaign%3Dshare`;
        http.get(`/store/share/${data}`)
            .catch((error) => {
              window.console.error(error);
            });
        setTimeout(() => {
          window.location.href = storeUrl;
        }, 500);
        return false;
      }
    },
    {
      path: '*',
      alias: '/404',
      name: 'Error',
      component: Error
    },
    {
      path: '/app/access',
      beforeEnter() {
        window.location.href = "https://play.google.com/store/apps/details?id=com.spreedl";
      }
    }
  ]
})
