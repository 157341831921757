const cryptoUtils = {
    base64Decode: (str) => {
        return Buffer.from(str, 'base64').toString('utf8');
    },

    base64Encode: (str) => {
        return Buffer.from(str, 'utf8').toString('base64');
    }
};

export default cryptoUtils;