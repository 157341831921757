<template>
  <div>
    <h2>PRÉAMBULE</h2>
    <article class="first">
      Dans le cadre du règlement général sur la protection des données (RGPD),
      ce document explique la politique de collecte et de traitement des données
      mise à en place par Spreedl sur l'ensemble de ses services.
      <br /><b>Spreedl</b> est édité par la société Stoney & Praly Sarl
      (Wuhrstrasse, 6 - 9490 Vaduz - Liechtenstein) qui s'engage à ce que la
      collecte et le traitement de vos données, effectués à partir des services
      Spreedl (site web, applications), soient conformes à la législation en
      vigueur. <br /><br />Pour toute information ou exercice de vos droits
      Informatique et Libertés sur les traitements de données personnelles gérés
      par la société <b>Stoney & Praly Sarl</b>, vous pouvez contacter son responsable de
      la protection des données par email en écrivant à <b>dpo@Spreedl.com</b>
    </article>
    <h2>1. DONNÉES COLLECTÉES</h2>
    <article>
      Nous recueillons des informations personnelles:
      <ul>
        <li><i class="far fa-circle"></i> lors de votre inscription sur nos services: adresse email, prénom,
          localisation, date de naissance, sexe, préférence sexuelle, photo,
          numéro de téléphone</li>
        <li><i class="far fa-circle"></i> lors du remplissage de votre profil:taille, poids, profession,
          tabac, situation familiale, religion, …</li>
        <li>
          <i class="far fa-circle"></i> lors de l'utilisation des services: adresse IP, Gélocalisation,
          durée de session, date et heure de connexion, caractéristiques
          technique de l'appareil utilisé, interactions avec les autres
          membres et toutes les actions réalisées pendant l'utilisation des
          services
        </li>
        <li>
          <i class="far fa-circle"></i> Lors de l'installation de l'application ou de visites des sites:
          source marketing (referer, campagnes marketing,
          partenaires)
        </li>
      </ul>
      <p>
        <strong>Cookies :</strong> Spreedl utilise des cookies sur ses services
        Web, la politique de confidentialité de ces cookies peuvent sont
         <a href="/cookies" class="text-underlined text-primary">disponibles ici</a>
      </p>
    </article>
    <h2>2. TRAITEMENT DES DONNÉES</h2>
    <article>
      Toute les informations que nous recueillons auprès de vous peuvent être
      utilisées pour :
      <ul>
        <li><i class="far fa-circle"></i> Gérer et modifier votre compte</li>
        <li><i class="far fa-circle"></i> Proposer une liste de personnes intéressantes selon vos critères</li>
        <li><i class="far fa-circle"></i> Analyser les comportements des membres à des fins d'amélioration ou
          d'ajout de nouveauté</li>
        <li><i class="far fa-circle"></i> Détecter et lutter contre l'utilisation frauduleuse des services
          (SCAM / SPAM)</li>
        <li><i class="far fa-circle"></i> Analyser et mesurer les performances des campagnes marketing</li>
        <li><i class="far fa-circle"></i> Contacter nos membres par email ou notifications</li>
      </ul>
    </article>
    <h2>3. DURÉE DE CONSERVATION</h2>
    <article>
      Spreedl conserve vos données pour la durée nécessaire aux opérations pour
      lesquelles elles ont été collectées dans le respect de la législation en
      vigueur.
    </article>
    <h2>4. CONFIDENTIALITÉ DES DONNÉES</h2>
    <article>
      Nous sommes les seuls propriétaires des informations recueillies sur ce
      site. Nous ne vendons pas ni divulguons, de quelque manière que ce soit,
      les données que nous recueillons à votre sujet à des tiers. Toutefois, si
      nous y sommes légalement obligés ou si une décision judiciaire nous
      l'impose, nous communiquerons vos données dans la mesure nécessaire pour
      se conformer à cette loi ou cette décision judiciaire.
      <br /><br />Certaines données peuvent être transmises à des tiers dans le
      cadre de prestations externes de service tels que l'analyse de donnée, le
      traitement et la sécurisation des paiements via internet.
      <br /><br /><strong>Transfert d'activités :</strong> Vos Données
      Personnelles peuvent être transmises à des tiers dans le cadre d'un
      transfert des activités de Spreedl vers une société tierce (fusion,
      cession, dissolution, etc.).
    </article>
    <h2>5. PROTECTION DES DONNÉES</h2>
    <article>
      Spreedl met en oeuvre une série de mesures de sécurité efficaces pour
      préserver la sécurité de vos informations personnelles et empêcher le vol
      ou l'altération de celles-ci. Néanmoins, le membre doit prendre toutes les
      précautions utiles afin de garantir son utilisation des services
      (antivirus, etc..) Seuls les employés qui ont besoin d'effectuer un
      travail spécifique (par exemple, la facturation ou le service à la
      clientèle) ont accès aux informations personnelles identifiables. Les
      ordinateurs et serveurs utilisés pour stocker des informations
      personnelles identifiables sont conservés dans un environnement sécurisé
      dans des datacenters présents dans différents pays.
    </article>
    <h2>6. VOS DROITS</h2>
    <article>
      En vertu de la législation européenne, vous disposez d'un droit de
      demander à Spreedl l'accès aux informations qui vous concernent, la
      rectification ou l'effacement de celles-ci, ainsi qu'une limitation du
      traitement. Vous disposez également d'un droit à la portabilité des
      données.
      <br /><br />Vous pouvez par ailleurs, dans les conditions prévues à
      l'article 21 du RGPD, vous opposer au traitement de vos Données
      Personnelles. A cet égard, vous pouvez vous désinscrire à tout moment des
      listes de diffusion depuis votre compte client. <br /><br />Vous pouvez
      également retirer votre consentement à tout moment, sans que ce retrait ne
      porte atteinte à la licéité du traitement effectué sur la base de ce
      consentement avant le retrait de celui-ci. Vous disposez enfin du droit
      d'introduire une réclamation auprès d'une autorité de contrôle (en France,
      la Commission National de l'Informatique et des Libertés – CNIL).
      <br /><br />Vous exercez les droits évoqués ci-dessus en écrivant à
      l'adresse suivante : dpo@spreedl.com. Spreedl traitera votre demande dans
      un délai maximum d'un mois suivant la réception de la demande, sauf
      circonstances particulières. <br />Cette demande doit être accompagnée
      d'une photocopie d'un titre d'identité portant signature du titulaire.
      <br /><br />Dans l'hypothèse où les demandes d'un membre seraient
      manifestement infondées ou excessives, notamment en raison de leur
      caractère répétitif, Spreedl pourrait exiger le paiement de frais
      raisonnables qui tiennent compte des coûts administratifs supportés pour
      fournir les informations, procéder aux communications ou prendre les
      mesures demandées, ou refuser de donner suite à ces demandes.
    </article>
    <h2>7. CONFIDENTIALITÉ RELATIVE AUX ENFANTS</h2>
    <article>
      Les services Spreedl sont strictement réservés aux personnes de 18 ans ou
      plus. Si vous suspectez qu'un membre est âgé de moins de 18 ans, veuillez
      le signaler à notre support via les outils présents dans les services.
    </article>
    <h2>8. MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
    <article>
      Spreedl réserve le droit de modifier les cette politique à tout moment
      sans préavis, afin de se conformer aux évolutions législatives en la
      matière.<br /><br />
      <u>Cette politique de Confidentialité a été mise à jour le 25/09/2021.</u>
    </article>
  </div>
</template>

<script>
export default {
  name: 'PrivacyFr'
}
</script>
