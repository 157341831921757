<template>
    <div id="scrollUp">
        <i class="fas fa-arrow-up"></i>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>