<template>
  <div class="simple-page">
    <div class="main">
      <HeaderSection />
      <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-8">
              <div class="welcome-intro">
                <h1 class="text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">{{ $t('newsletter.TITLE') }}</h1>
              </div>
            </div>
          </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape-bottom">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
          </svg>
        </div>
      </section>
      <div class="container =">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 pb-5 mt-4 mt-md-0">
            <md-card>
              <md-card-header>
                <div class="md-title">{{ $t('newsletter.UNSUBSCRIBE_TITLE') }}</div>
              </md-card-header>
              <md-card-content>
                <p v-show="!processed" class="size">
                  <strong>{{ $t('newsletter.UNSUBSCRIBE_TEXT_1', {newsletter: getNewsletterType}) }}</strong>
                  <br>{{ $t('newsletter.UNSUBSCRIBE_TEXT_2') }}
                </p>
                <p v-show="processed">
                  <strong>{{ $t('newsletter.UNSUBSCRIBE_TEXT_SUCCESS_1', {newsletter: getNewsletterType}) }}</strong>
                  <br><br>{{ $t('newsletter.UNSUBSCRIBE_TEXT_SUCCESS_2') }}
                </p>
              </md-card-content>
              <md-card-actions v-show="!processed">
                <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" v-show="processing" class="mr-3"></md-progress-spinner>
                <md-button class="md-raised md-primary" v-show="!processing" @click="unsubscribe">{{ $t('common.CONFIRM')}}</md-button>
              </md-card-actions>
            </md-card>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>

import HeaderSection from '@/components/common/HeaderSimple'
import FooterSection from '@/components/common/FooterLegal'
import http from "@/utils/http-common";

export default {
  name: 'UnsubscribePage',
  components: {
    HeaderSection,
    FooterSection,
  },
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('newsletter.TITLE')} - %s`
    }
  },
  props: {
    params: {
      type: String,
      default: () => ""
    },
    newsletterType: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      processed: false,
      processing: false,
    }
  },
  methods: {
    unsubscribe() {
      this.processing = true;
      http.post("/newsletter/unsubscribe", {
        data: this.params
      })
      .then(response => {
        if (response.data.status) {
          this.processed = true;
        }
        else {
          this.$toast.error(response.data.message);
        }
        this.processing = false;
      })
      .catch(() => {
        this.processing = false;
        this.$toast.error(this.$t("error.GLOBAL").toString());
      })
    }
  },

  create() {
    if (this.params === "" || this.newsletterType === "") {
      this.$router.push('/404');
    }
  },
  computed: {
    getNewsletterType: function() {
      return this.$t(`newsletter.TYPE_${this.newsletterType}`)
    }
  }
}
</script>
