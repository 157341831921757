import * as types from '@/store/mutation-types';
import i18n from '@/plugins/i18n';
import * as Validator from 'vee-validate';
import config from "@/config/config";

const getters = {
  locale: (state) => state.locale
}

const actions = {
  setLocale({ commit }, payload) {
    if (config.supportedLocales.includes(payload)) {
      i18n.locale = payload
      Validator.localize(payload)
      window.localStorage.setItem('locale-lg', JSON.stringify(payload))
      commit(types.SET_LOCALE, payload)
    }
  }
}

const mutations = {
  [types.SET_LOCALE](state, value) {
    state.locale = value
  }
}

const state = {
  locale:
    JSON.parse(localStorage.getItem('locale-lg')) ||
    'fr'
}

export default {
  state,
  getters,
  actions,
  mutations
}
