<template>
  <div>
    <article class="first">
      La rubrique d'aide sera bientôt disponible.
    </article>
  </div>
</template>

<script>
export default {
  name: 'HelpFr'
}
</script>
