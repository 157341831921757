<template>
  <div>
    <div class="row d-flex justify-content-center" v-show="componentName == null">
      <vue-ellipse-progress
          v-show="componentName == null"
          :loading="componentName == null"
          :angle="-90"
          color="#50a5f1"
          emptyColor="#999"
          emptyColorFill="#f8f8fb"
          :size="100"
          :thickness="3"
          :emptyThickness="2"
          lineMode="out 2"
          animation="bounce 700 400"
          fontColor="#666"
          :half="false"
          class="mt-5 mb-5"
          fontSize="1rem">
      </vue-ellipse-progress>
    </div>
    <component v-if="componentName != null" v-bind:is="componentName" :ccData="ccData" :params="params"></component>
  </div>
</template>

<script>

import paymentConfig from "@/config/payment.config"
import CardPP from "./CardPP.vue";
import CardSP from "./CardSP.vue";
import CardHP from "./CardHP.vue";
import isEmpty from "is-empty";
import http from "@/utils/http-common";

export default {
  name: "PaymentCard",

  components: {
    CardPP,
    CardSP,
    CardHP
  },

  props: {
    params: {
      type: String,
      default: () => ""
    },
  },

  data() {
    return {
      componentName: null,
      ccData: []
    }
  },

  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('payments.TITLE')} - %s`,
    }
  },

  created() {
    if (isEmpty(this.params)) {
      this.$router.push('/404');
    }
  },

  mounted() {
    this.checkParams();
  },

  methods: {

    getProvider() {
      this.componentName = "Card" + paymentConfig.getProvider().toUpperCase();
    },

    checkParams() {
      http.post("/payments/check-data", {
        data: this.params,
      })
          .then((response) => {
            if (!response.data.status || isEmpty(response.data.result.providerId)) {
              this.getProvider();
            }
            else {
              const pid = paymentConfig.getProviderDefined(response.data.result.providerId);
              if (pid === response.data.result.providerId && !isEmpty(response.data.result.cc)) {
                this.ccData = response.data.result.cc;
              }
              this.componentName = "Card" + pid.toUpperCase();
            }
          })
          .catch(() => {
            this.getProvider();
          });
    }
  }
}
</script>

<style scoped>


</style>
