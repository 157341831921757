<template>
  <div>
    <p class="p-5 text-center">{{ $t("payments.SUCCESS_WAITING")}}</p>
    <div class="justify-content-center mt-2 text-center">
      <md-progress-spinner  md-mode="indeterminate" class="mt-4"></md-progress-spinner>
    </div>
  </div>
</template>

<script>

export default {
  name: "SuccessResult",

  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('payments.TITLE')} - %s`,
    }
  },
}
</script>

<style scoped>

</style>