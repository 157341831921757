<template>
  <div>
    <h2>Définitions</h2>
    <article class="first">
      <ul>
        <li>
          <b>Conditions Générales :</b> désigne le présent contrat.
        </li>
        <li>
          <b>Application: </b> désigne un ensemble de Services ayant pour objet de
          créer des rencontres virtuelles entre Membres à des fins personnelles et
          non commerciales, L'Application <strong>SPREEDL</strong> est éditée par la
          société <u>Stoney & Praly Sarl</u>.
        </li>
        <li>
          <b>Membre(s) :</b> désigne un (ou l'ensemble des) utilisateur(s) incrit(s)
          et identifié(s) sur notre Application.
        </li>
        <li>
          <b>Service(s) :</b> désigne l'ensemble des fonctionnalités accessibles aux
          Membres sur L'Application.
        </li>
        <li>
          <b>Abonnement :</b> désigne un forfait payant donnant accès à l'ensemble
          des Services de l'Application. Tout Abonnement est renouvelable tacitement
          par périodes équivalentes à celle initiale (sauf indications contraires
          explicites)
        </li>
      </ul>
    </article>
    <h2>Conditions d'Accès à l'Application</h2>
    <article>
      Tous les équipements (ordinateur, logiciel, liaison internet, etc...)
      permettant l'accès à l'Application sont à la charge exclusive du
      Membre.<br />
      Pour devenir Membre de notre Application, vous devez être âgé(e) au
      minimum de 18 ans et remplir valablement l'ensemble des données
      obligatoires présentes sur le formulaire d'inscription.<br /><br />
      Le Membre garantie que les données qu'il inscrit sont correctes et
      conformes à son identité. Le Membre s'engage à informer le Application par
      le formulaire de contact, ou à procéder lui-même à la modification de ces
      données par le biais du Service.<br /><br />
      Lorsque le formulaire d'inscription est correctement rempli et validé, le
      Membre dispose, définis par lui-même ou par le Application, d'un
      identifiant unique (pseudo) et d'un mot de passe lui servant à
      s'identifier sur le Application.<br /><br />
      L'Application se réserve le droit de supprimer sans délai le compte du
      Membre si celui-ci diffuserait ou utiliserait des informations
      frauduleuses. L'Application n'ayant pas pour obligation et ne disposant
      pas des moyens techniques de s'assurer de l'identité des personnes
      s'inscrivant à ses Services, L'Application n'est pas responsable en cas
      d'usurpation de l'identité d'un Membre. Si le Membre a des raisons de
      penser qu'une personne utilise ses éléments d'identification ou son
      compte, il devra en informer immédiatement l'Application. L'Application ne peut être tenue
      responsable d'actes malveillants commis entre membres (escroquerie, chantage, etc...).<br /><br />
      L'Application ne peut, en outre, être tenu responsable de tout
      dysfonctionnement du réseau ou des serveurs ou de tout autre événement
      échappant au contrôle raisonnable, qui empêcherait ou dégraderait l'accès
      au Service.<br />
      L'Application se réserve la possibilité d'interrompre, de suspendre
      momentanément ou de modifier sans préavis l'accès à tout ou partie des
      Services, afin d'en assurer la maintenance, ou pour toute autre raison,
      sans que l'interruption n'ouvre droit à aucune obligation ni
      indemnisation.
    </article>
    <h2>Conditions d'Utilisation</h2>
    <article>
      Le membre s'engage à :
      <ul>
        <li>
          <i class="far fa-circle"></i> Etre courtois à l'égard de l'Application et des
          Membres
        </li>
        <li><i class="far fa-circle"></i> Respecter les droits de propriété intellectuelle correspondant aux
          contenus mis à disposition par L'Application et les
          Membres
        </li>
        <li><i class="far fa-circle"></i> Ne pas publier de contenu diffamatoire, insultant, calomnieux,
          raciste, violent, politique ou pornographique
        </li>
        <li><i class="far fa-circle"></i> Ne pas publier des informations personnelles (adresse
          email/postale, numéro de téléphone, etc...) fournis par un autre
          Membre
        </li>
        <li><i class="far fa-circle"></i> Ne pas utiliser notre service de rencontre à des fins
          professionnelles ou commerciales (prospection, racolage ou
          prostitution) ou non privées.
        </li>
        <li><i class="far fa-circle"></i> Respecter d'une manière générale l'ensemble de la réglementation en
          vigueur en Europe.
        </li>
      </ul>
      Tout manquement à ces obligations entrainera une suppression irrévocable
      du Membre.<br /><br />
      <strong>Obligation de l'Application :</strong><br />
      L'Application assure à distance et par voie électronique la fourniture
      d'un Service de rencontres virtuelles via des services de communications
      électroniques. L'objet de l'Application n'est pas le conseil ni le
      courtage matrimonial en vue de la réalisation d'un mariage ou
      l'établissement d'une union stable.<br /><br />
      L'Application ne peut être tenu pour responsable des fausses informations
      fournies par un Membre. L'Application se dégage de toute responsabilité
      lors de rencontres réelles entre Membres, sur L'Application ou lors de
      rencontres entre Membres et/ou non- membres, suite à l'utilisation de
      l'Application. Par ailleurs, lors d'une première rencontre, il est
      recommandé de prévenir une personne proche et de préférer un lieu public
      (café, restaurant, etc...).<br /><br />
      Toute information transmise par le Membre au Site sera traité de manière
      strictement confidentielle. Toutefois, L'Application ne sera pas tenu
      responsable d’une violation de confidentialité due à des accidents
      relatifs à des dysfonctionnements dans les moyens de transmission par
      Internet ou par courrier postal (piratage, violation de la sécurité des
      messageries Internet, etc...).
    </article>
    <h2>Protection des Données Personnelles</h2>
    <article>
      Dans une logique de respect de la vie privée de ses Membres, L'Application
      s'engage à ce que la collecte et le traitement d'informations
      personnelles, effectués au sein du présent site, soient effectués
      conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique,
      aux fichiers et aux libertés, dite Loi « Informatique et Libertés». Le
      Site se réserve le droit d'envoyer des pop ups publicitaires ciblés au
      Membre. Conformément à l'article 34 de la loi « Informatique et Libertés
      », L'Application garantit au Membre un droit d'opposition, d'accès et de
      rectification sur les données nominatives le concernant.<br /><br />
      Toutes les informations collectées par L'Application (adresse IP de chaque
      connexion, adresse email, etc...) peuvent être transmises aux autorités
      juridiques et/ou policières compétentes dans le cas d'une demande de
      commission rogatoire.
    </article>
    <h2>Abonnement</h2>
    <article>
      <strong>Modalités de paiement d'un Abonnement</strong><br />
      Le Membre peut acquérir un Abonnement soit par carte bancaire (par le
      biais de notre page sécurisée de paiement), soit par
      d'autres types (Google Play Store, Apple Store, etc...) disponibles selon le pays de
      résidence du Membre.<br /><br />
      Les prix des différents Abonnements et les modalités de paiement sont
      accessibles lors de la présentation de la page de paiement sécurisé. Les
      prix affichés sont toujours TTC (toutes taxes comprises) hors frais
      bancaires éventuels de la banque du Membre. Tous les Abonnements
      supérieurs à 1 mois sont payables d'avance en une fois pour toute la durée
      de l'Abonnement.<br />
      La validation de l'Abonnement est quasi immédiat lors d'une transaction
      acceptée par carte bancaire. Pour les autres modalités de paiement,
      l'Abonnement peut être validé et effectif après plusieurs jours. Le Membre
      certifie que les données renseignées lors du paiement de l'Abonnement sont
      exactes et sincères.<!--<br /><br />
      Lorsqu'un Abonnement est validé, sous réserve de la validité de l'adresse
      email du Membre, un courriel de confirmation du paiement contenant toutes
      les informations pertinentes (numéro d'abonnement, numéro de transaction
      bancaire...) est envoyé au Membre.-->
    </article>
    <article>
      <strong>Modalités de renouvellement d'un Abonnement</strong
      ><br />
      A l'expiration d'un Abonnement, sauf résiliation de la part du Membre,
      l'Abonnement est renouvellé tacitement pour la même période que celle
      initiale. D'autres modalités spécifiques de reconduction peuvent exister,
      elles sont alors affichées clairement dans la page de paiement de
      l'Application.<br />L'offre "Premium à Vie" est une adhésion unique, illimitée et valide pendant
      la durée de vie du compte client et de l'Application.<br />
      Lors d'un renouvellement, si l'Abonnement a été payé par carte bancaire,
      L'Application se réserve le droit de fractionner le montant de
      l'Abonnement en plusieurs transactions bancaires (sans frais
      supplémentaires pour le Membre) réparties par périodes égales pendant la
      durée de l'Abonnement.<br /><br />
      <strong>Période d'engagement d'un Abonnement</strong><br />
      Sauf indication contraire, nos abonnements sont sans période d'engagement.
      Dans le cadre d'un Abonnement avec période minimum d'engagement, le Membre
      bénéficie d'un tarif préférentiel durant cette période d'engagement, en
      contrepartie, l'Abonnement ne peut être résilié avant le dernier
      prélèvement obligatoire executé. A la fin de la période d'engagement, sauf
      résiliation de la part du Membre, l'Abonnement est reconduit sur la même
      période, sans engagement, et sans tarif mensuel préférentiel.<br /><br />
      <strong>Modalités de résiliation d'un Abonnement</strong><br />
      <u>Abonnement par carte bancaire</u><br />
      Le Membre peut résilier un Abonnement depuis la rubrique "Mon Compte" de
      l'Application en respectant les conditions de résiliation (durée minimum, etc…).
      <br><br>
      <u>Abonnement par Store</u><br />
      Pour résilier un abonnement payé avec un compte store (Google Play Store, Apple AppStore), il suffit de vous connecter à votre compte store et de résilier depuis celui-ci.
      <br /><br />
      En aucun cas la résiliation ne peut donner lieu à un remboursement de la
      période d'Abonnement non utilisée. Lorsque la résiliation est validée, le
      Membre continue à avoir accès aux Services jusqu'à la fin de la période
      d'Abonnement payée.
    </article>
    <h2>Suppression du Membre</h2>
    <article>
      Le Membre peut décider de supprimer son compte à partir de la rubrique
      "Mon Compte" présente sur L'Application. Toutefois, pour pouvoir supprimer
      son compte, le Membre ne doit être en la possession d'un Abonnement non
      résilié. Il est nécessaire de résilier l'Abonnement avant de supprimer le
      compte. Attention: toute suppression du compte est irréversible !
    </article>
    <h2>Propriété Intellectuelle</h2>
    <article>
      L'Application, ainsi que tous ses contenus (nom de domaine, éléments
      graphiques, textes, etc...) est protégé par le droit en vigueur en Europe.
      En aucun cas, le Membre ne peut, sans le consentement écrit de
      l'Application, reproduire, copier, publier, transmettre, communiquer ou
      utiliser de quelque façon que ce soit tout ou partie de ses contenus. Le
      contrevenant s'expose à des sanctions civiles et pénales et notamment aux
      peines prévues aux articles L. 335.2 et L. 343.1 du code de la Propriété
      Intellectuelle.
    </article>
    <h2>Responsabilité</h2>
    <article>
      Les Services sont exclusivement réservés à un public majeure. Le Membre
      admet connaître les limitations et contraintes propres au réseau
      internet.<br /><br />
      Le Membre est le seul responsable des dommages et préjudices, directs ou
      indirects, matériels ou immatériels dès lors qu'ils auraient pour cause,
      fondement ou origine une utilisation frauduleuse ou non de l'Application
      par le Membre ou par toute personne autorisée par celui-ci.<br /><br />
      La responsabilité de l'Application ne peut être engagée dans les cas
      suivants : interruptions momentanées de l'Application (pour cause
      électricité, mise à jour, pannes serveurs, etc...).
    </article>
    <h2>Juridiction, Litiges et Réclamations</h2>
    <article>
      Les lois de la Suisse régissent ces Conditions Générales ainsi que
      votre utilisation du Service. Votre utilisation du Service peut également
      être soumise à d'autres lois locales, nationales ou internationales. Si
      vous êtes un Membre issu d'un pays de l'Union européenne, vous
      bénéficierez de toutes les dispositions obligatoires de la loi du pays
      dans lequel vous résidez.
      <br /><br />Toute tentative de fraude ou de contestation fera l'objet d'un
      dépôt de plainte et sera systématiquement poursuivie.<br /><br />
      Les parties rechercheront, avant toute action contentieuse, un accord
      amiable entre elles. A défaut, les parties se réservent la possibilité de
      de saisir le Tribunal de Commerce compétent.
    </article>
    <h2>Nous contacter</h2>
    <article>
      Pour toutes questions, veuillez contacter un conseiller par le biais de
      notre formulaire de contact ou par email.<br /><br /><strong>Stoney & Praly Sarl</strong><br />Grande-Rue 16<br />2400
      Le Locle<br />Suisse<br /><br />Email: {{ contactAddress }}
    </article>
    <h2>Rectification légale</h2>
    <article>
      L'Application se réserve le droit de modifier les Conditions Générales à
      tout moment.
    </article>
    <h2 class="mt-3">Attributions</h2>
    <article>
      <b>Icons and illustrations</b><br /><br />
      <ul>
        <li>
          <i class="far fa-circle"></i> FreePik <a href='https://www.freepik.com/'>Flat vector created by freepik - www.freepik.com</a>
        </li>
        <li>
          <i class="far fa-circle"></i> Flaticon <a href="https://www.flaticon.com/" title="high tide icons">Icons created by Freepik - Flaticon</a>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  name: 'TermsEn',
  data: () => ({
    contactAddress: 'customer@spreedl.com'
  })
}
</script>
