<template>
    <header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
            <div class="container position-relative">
                <a class="navbar-brand" href="#">
                    <img class="navbar-brand-sticky" src="@/assets/img/logo.png" alt="sticky brand-logo">
                </a>
                <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-inner">
                    <!--  Mobile Menu Toggler -->
                    <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <nav>
                        <ul class="navbar-nav" id="navbar-nav">
                            <!--<li class="nav-item">
                                <a class="nav-link scroll" href="/">{{ $t("menu.HOME") }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#features">{{ $t("menu.FEATURES") }}</a>
                            </li>-->
                            <!--<li class="nav-item">
                              <a class="nav-link scroll" href="#screenshots">{{ $t("menu.SCREENSHOTS") }}</a>
                            </li>-->
                            <LocaleChanger />
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleChanger from '@/components/common/LocaleChanger'
import ResizeText from 'vue-resize-text'

export default {
  name: 'HeaderSection',
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      meta: [
        { name: 'msapplication-TileColor', content: '#ffc40d' },
        { name: 'theme-color', content: '#ffffff' },
        {
          name: 'apple-mobile-web-app-title',
          content: this.$store.getters.appTitle
        },
        { name: 'application-name', content: this.$store.getters.appTitle }
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
        { rel: 'favicon', href: '/favicon.ico' }
      ]
    }
  },
  components: {
    LocaleChanger
  },
  directives: {
    ResizeText
  },
  computed: {
    ...mapGetters(['appTitle'])
  }
}
</script>

<style>

</style>
