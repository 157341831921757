import { render, staticRenderFns } from "./SecureResult.vue?vue&type=template&id=22ff604a&scoped=true"
import script from "./SecureResult.vue?vue&type=script&lang=js"
export * from "./SecureResult.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ff604a",
  null
  
)

export default component.exports