<template>
    <div class="inner">
        <ScrollupSection />
        <div class="main">
            <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
                <div class="container h-100">
                    <div class="row align-items-center justify-content-center h-100">
                        <div class="col-12 col-md-7">
                            <div class="welcome-intro error-area text-center">
                                <h1 class="text-white">404</h1>
                                <p class="text-white my-4">{{ $t("error.TXT_400") }}</p>
                                <a href="/" class="btn sApp-btn text-uppercase">{{ $t("error.GO_HOME") }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import ScrollupSection from '../../ScrollupSection/scrollUp'

export default {
    name: 'ErrorPage',
    components: {
        ScrollupSection
    }
}
</script>

<style>

</style>