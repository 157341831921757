<template>
  <div>
    <p class="p-5 text-center">{{ $t("payments.SECURE3D_WAITING")}}</p>
    <div class="justify-content-center mt-2 text-center">
      <md-progress-spinner  md-mode="indeterminate" class="mt-4"></md-progress-spinner>
    </div>
  </div>
</template>

<script>

import isEmpty from "is-empty";
import paymentConfig from "@/config/payment.config";
export default {
  name: "SecureResult",

  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('payments.TITLE')} - %s`,
    }
  },

  methods: {

    logD(message) {
      if (typeof window.Android !== "undefined" && paymentConfig.config.pp.debug)
        window.Android.setLogConsole("D", message);
      else if (paymentConfig.config.pp.debug) {
        window.console.log(message);
      }
    },
  },

  mounted() {
    const qs = this.$route.query;
    if (!isEmpty(qs)) {
      this.logD("qs is", JSON.stringify(qs));
    }
  },
}
</script>

<style scoped>

</style>