<template>
  <div class="pb-5">
    <section id="features" class="section features-area overflow-hidden pb-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-4">
                        <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                            <i class="far fa-lightbulb text-primary mr-1"></i>
                            <span class="text-primary">Premium</span>
                            {{ $t("landing.FEATURES") }}
                        </span>
                        <h2>{{ $t("landing.FEATURE_TITLE_2") }}</h2>
                        <p class="d-block mt-4">{{ $t("landing.FEATURE_SUBTITLE") }}</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-12 col-lg-6 text-center">
                  <img class="featured-img"  src="@/assets/img/spreedl_game.jpg" />
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Image Box -->
                    <div class="text-left p-5 bg-light" style="border-radius: 25px;">
                        <h2 class="mb-3 feature-title">{{ $t("landing.FEATURE_SPREEDL_TITLE") }}</h2>
                        <p class="feature-text text-dark" v-html="$t('landing.FEATURE_SPREEDL_TEXT')"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section features-area bg-light overflow-hidden">
        <div class="container">
            <div class="row align-items-center">
                <!--<div class="col-12 col-md-6 col-lg-4 res-margin">
                    <div class="image-box text-center icon-1 p-5 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="featured-img mb-3">
                            <img class="avatar-sm" src="@/assets/img/featured_image_4.png" alt="">
                        </div>
                        <div class="icon-text">
                            <h3 class="mb-2">{{ $t("landing.FEATURE_2_TITLE") }}</h3>
                            <p>{{ $t("landing.FEATURE_2_TEXT") }}</p>
                        </div>
                    </div>
                </div>-->
              <div class="col-12 col-lg-6">
                    <div class="text-left p-5">
                      <h2 class="mb-3 feature-title">{{ $t("landing.FEATURE_VIDEO_TITLE") }}</h2>
                      <p class="feature-text" v-html="$t('landing.FEATURE_VIDEO_TEXT')"></p>
                    </div>
               </div>
              <div class="col-12 col-lg-6">
                <img class="featured-img"  src="@/assets/img/feature_video.jpg" />
              </div>
            </div>
        </div>
    </section>
    <section class="section features-area features-misc overflow-hidden">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 p-5">
            <div class="image-box bg-white text-center pb-4 pr-5 pl-5">
              <div class="featured-img mb-1">
                <lottie-player id="lottieVideoLike" src="" background="transparent" speed="1" style="margin:0 auto;width: 200px; height: 130px" direction="1" mode="normal" loop autoplay></lottie-player>
              </div>
              <div class="icon-text">
                <h3 class="mb-2 feature-title">{{ $t("landing.FEATURE_1_TITLE") }}</h3>
                <p>{{ $t("landing.FEATURE_1_TEXT") }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 p-5">
            <div class="image-box bg-white text-center pb-4 pr-5 pl-5">
              <div class="featured-img mb-1">
                <lottie-player id="lottieMessenger" src="" background="transparent" speed="1" style="margin:0 auto;width: 200px; height: 130px" direction="1" mode="normal" loop autoplay></lottie-player>
              </div>
              <div class="icon-text">
                <h3 class="mb-2 feature-title">{{ $t("landing.FEATURE_3_TITLE") }}</h3>
                <p>{{ $t("landing.FEATURE_3_TEXT") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getStorageUrl } from "@/utils/firebase"

export default {
  name: 'FeatureSection',

  mounted() {
    getStorageUrl("web/videolike.json")
        .then(url => {
          const player = document.getElementById("lottieVideoLike");
          player.load(url);
        });

    getStorageUrl("web/messenger.json")
        .then(url => {
          const player = document.getElementById("lottieMessenger");
          player.load(url);
        });
  }
}
</script>

<style>

.features-misc {
  background: rgb(255,97,97);
  background: linear-gradient(90deg, rgba(255,97,97,1) 0%, rgba(223,54,142,1) 100%);
}

h2.feature-title {
  font-family: "Roboto Thin", "Roboto", sans-serif;
  font-weight: 400;
  font-size:35px;
  color:#0d95e8;
}

h3.feature-title {
  font-family: "Roboto Thin", "Roboto", sans-serif;
  font-weight: 400;
  color:#FF6161;
}

p.feature-text {
  font-size:17px;
  font-family: "Roboto Thin", "Roboto", sans-serif;
}
</style>
