<template>
  <div class="simple-page">
    <div class="main">
      <HeaderSection />
      <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-8">
              <div class="welcome-intro">
                <h1 class="text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">{{ $t('validateEmail.TITLE') }}</h1>
              </div>
            </div>
          </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape-bottom">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
          </svg>
        </div>
      </section>
      <div class="container =">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 pb-5 mt-4 mt-md-0">
            <md-card>
              <md-card-header>
                <div class="md-title">{{ $t('validateEmail.VALIDATE_EMAIL_TITLE') }}</div>
              </md-card-header>
              <md-card-content>
                <p v-show="processing" class="size">
                  <strong>{{ $t('validateEmail.TEXT_PROCESS_1') }}</strong>
                  <br>{{ $t('validateEmail.TEXT_PROCESS_2') }}
                </p>
                <p v-show="!processing && processed">
                  <strong>{{ $t('validateEmail.TEXT_SUCCESS_1') }}</strong>
                </p>
                <p v-show="!processing && !processed">
                  <strong>{{ $t('validateEmail.TEXT_ERROR_1') }}</strong>
                </p>
                <p v-show="!processed">
                  <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" v-show="processing" class="mr-3"></md-progress-spinner>
                </p>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script>

import HeaderSection from '@/components/common/HeaderSimple.vue'
import FooterSection from '@/components/common/FooterLegal.vue'
import http from "@/utils/http-common";
import isEmpty from "is-empty"

export default {
  name: 'ValidateEmailPage',
  components: {
    HeaderSection,
    FooterSection,
  },
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('validateEmail.TITLE')} - %s`
    }
  },
  props: {
    params: {
      type: String,
      default: () => ""
    },
    action: {
      type: String,
      default: () => ""
    },
  },
  data() {
    return {
      processed: false,
      processing: false,
    }
  },
  methods: {
    validate() {
      this.processing = true;
      http.post("/account/confirm-email", {
        data: this.params,
        action: this.action
      })
      .then(response => {
        if (response.data.status) {
          this.processed = true;
        }
        else {
          this.$toast.error(response.data.message);
        }
        this.processing = false;
        if (!isEmpty(response.data.result.redirectUrl)) {
          setTimeout(() => {
            window.location.replace(response.data.result.redirectUrl);
          }, 500);
        }
      })
      .catch(() => {
        this.processing = false;
        this.$toast.error(this.$t("error.GLOBAL").toString());
      })
    }
  },

  create() {
    if (this.params === "") {
      this.$router.push('/404');
      return;
    }
  },

  mounted() {
    setTimeout(() => {
      this.validate();
    }, 500);
  }
}
</script>
