<template>
  <div>
    <article class="first">
      Help section wille be soon available.
    </article>
  </div>
</template>

<script>
export default {
  name: 'HelpEn'
}
</script>
