<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import {
  setDocumentTitle,
  setDocumentLang,
  setDocumentMetaDescription,
  setDocumentMetaKeywords
} from "@/utils/i18n"

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      meta: [
        {name: 'msapplication-TileColor', content: '#ffc40d'},
        {name: 'theme-color', content: '#ffffff'},
        {
          name: 'apple-mobile-web-app-title',
          content: this.appTitle
        },
        {name: 'application-name', content: this.appTitle}
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        {rel: 'manifest', href: '/site.webmanifest'},
        {rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg'},
        {rel: 'favicon', href: '/favicon.ico'}
      ],
    }
  },

  computed: {
    appTitle() {
      return this.$store.getters.appTitle
    }
  },

  mounted() {
    this.$watch(
        "$i18n.locale",
        (newLocale, oldLocale) => {
          if (newLocale === oldLocale) {
            return
          }
          setDocumentLang(newLocale)
          setDocumentTitle(this.$t("meta.TITLE"))
          setDocumentMetaDescription(this.$t("meta.DESCRIPTION"))
          setDocumentMetaKeywords(this.$t("meta.KEYWORDS"))
        },
        { immediate: true }
    )
  }
}
</script>

<style>

</style>
