<template>
    <footer class="footer-area">
            <!-- Footer Top -->
            <div class="footer-top pb-5 pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Logo -->
                                <a class="navbar-brand" href="#">
                                    <img class="logo" src="@/assets/img/logo_spreedl_txt.png" alt="">
                                </a>
                              <!-- Social Icons -->
                                <!--
                                <div class="social-icons d-flex">
                                    <a class="facebook" href="#">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" href="#">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="google-plus" href="#">
                                        <i class="fab fa-google-plus-g"></i>
                                        <i class="fab fa-google-plus-g"></i>
                                    </a>
                                    <a class="vine" href="#">
                                        <i class="fab fa-vine"></i>
                                        <i class="fab fa-vine"></i>
                                    </a>
                                </div>
                                -->
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Footer Title -->
                                <h3 class="footer-title mb-2">{{ $t("landing.FOOTER_LINKS") }}</h3>
                                <ul>
                                    <li class="py-2"><a href="#">{{ $t("menu.HOME") }}</a></li>
                                    <li class="py-2"><a href="/terms">{{ $t("terms.TITLE") }}</a></li>
                                    <li class="py-2"><a href="/privacy">{{ $t("privacy.TITLE") }}</a></li>
                                    <li class="py-2"><a href="/cookies">{{ $t("cookies.TITLE") }}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <!-- Footer Items -->
                            <div class="footer-items">
                                <!-- Footer Title -->
                                <h3 class="footer-title mb-2">Download</h3>
                                <!-- Store Buttons -->
                                <div class="button-group store-buttons store-black d-flex flex-wrap">
                                    <a href="https://play.google.com/store/apps/details?id=com.spreedl&referrer=utm_source%3Dspreedl.com%26utm_campaign%3Dfree%26utm_medium%3Dsite%26utm_content%3Dps_btn_footer">
                                        <img src="@/assets/img/google_play_black.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer Bottom -->
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Copyright Area -->
                            <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                <!-- Copyright Right -->
                                <div class="copyright-right">&copy; {{ new Date().getFullYear() }} <strong>Spreedl</strong> <i class="fas fa-heart"></i> {{ $t("landing.FOOTER_BY") }} Stoney & Praly Sarl</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>

<style>

</style>
