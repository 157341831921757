<template>
  <div></div>
</template>

<script>
import cryptoUtils from "@/utils/crypto";

export default {
  name: "SecurePage",

  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('payments.TITLE')} - %s`,
    }
  },

  props: {
    params: {
      type: String,
      default: () => ""
    },
  },

  data() {
    return {
      secureContent: ""
    }
  },

  methods: {
    loadContent() {
      const data = window.sessionStorage.getItem(this.params);
      this.secureContent = cryptoUtils.base64Decode(data);
    }
  },

  mounted() {
    this.loadContent();
  }
}
</script>

<style scoped>

</style>